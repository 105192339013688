import React from "react";
import Thanks from "../components/thankyou/Thanks";

const ThanksPage = () => {
    return (
        <div>
            <Thanks />
        </div>
    )
}
export default ThanksPage;