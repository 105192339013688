import React from "react";
import Policy from "../components/policy/Policy";


const Private = () => {
    return (
        <div>
            <Policy />
        </div>
    )
}

export default Private;