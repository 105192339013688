import React from "react";
import Navbar from "../components/navbar/Navbar";
import Abuse from "../components/abuse/Abuse";
import Footer from "../components/footer/Footer";


const Drug = () => {
    return (
      <div>
        <Navbar />
        <Abuse />
        <Footer />
        
      </div>
    );
}

export default Drug;