import React from "react";
import Arrest from "../components/arrest/Arrest";

const Vpolicy = () => {
    return (
        <div>
            <Arrest />
        </div>
    )
}

export default Vpolicy;