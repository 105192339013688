import React from "react";
import Join from "../components/join/Join";


const Signup = () => {
    return (
        <div>
           <Join />
        </div>
    );
};

export default Signup;